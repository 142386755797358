import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

// Importing all pages
// import Index from "./pages/Index";
// import Walletpi from "./pages/Walletpi";
import { initMetaPixel, trackPageView } from "./utils/metaPixel";
import Products from "./pages/products";
import Walletpi from "./pages/wallet-pi";
import PiNetworkConverter from "./pages/address-shipping";
import { getCollectionData, db } from "./utils/firebase";
import { get } from "lodash";
import { generateRandom } from "./utils/random";
import { doc, onSnapshot } from "firebase/firestore";

export interface AppProps {
  lang: string;
}

const LAG_KEY = "__lagging";
const SITE_TRIGGER_ID = window.location.host;

const collectionRef = "simulate_lag"; // Reference to the collection
const documentRef = doc(db, collectionRef, SITE_TRIGGER_ID); // Reference to the document

function App() {
  const [isLoading, setIsLoading] = useState(
    localStorage.getItem(LAG_KEY) === "true"
  );

  useEffect(() => {
    const pixelId = "952811763271292"; // Replace with your Meta Pixel ID
    initMetaPixel(pixelId);
    trackPageView(); // Track page view when the app is loaded
  }, []);

  useEffect(() => {
    // Set up real-time listener
    const unsubscribe = onSnapshot(documentRef, (docSnapshot) => {
      const item = docSnapshot.data();

      localStorage.setItem(LAG_KEY, String(item && item.enabled === true));
    });

    return () => unsubscribe();
  }, []);

  useLayoutEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (localStorage.getItem(LAG_KEY) === "true") {
      const randomTime = generateRandom(4000, 8000, 500);
      timer = setTimeout(() => {
        setIsLoading(false);
      }, randomTime);
    } else {
      setIsLoading(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Loading...
      </div>
    ); // Customize this loading screen as needed
  }

  return (
    <Routes>
      {/* en */}
      <Route path="/" element={<Products lang="en" />} />
      <Route path="/en" element={<Products lang="en" />} />
      <Route
        path="/en/shipping-address"
        element={<PiNetworkConverter lang="en" />}
      />
      <Route path="/en/walletpi" element={<Walletpi lang="en" />} />

      {/* vi */}
      <Route path="/vi" element={<Products lang="vi" />} />
      <Route
        path="/en/shipping-address"
        element={<PiNetworkConverter lang="en" />}
      />
      <Route path="/vi/walletpi" element={<Walletpi lang="vi" />} />

      {/* cn */}
      <Route path="/cn" element={<Products lang="cn" />} />
      <Route path="/cn/walletpi" element={<Walletpi lang="cn" />} />
    </Routes>
  );
}

export default App;
