import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDnqY80zals-0Hx7il1rHwb5udYniKHTo8",
  authDomain: "fe-support-tools.firebaseapp.com",
  projectId: "fe-support-tools",
  storageBucket: "fe-support-tools.appspot.com",
  messagingSenderId: "127806752671",
  appId: "1:127806752671:web:5ec7deca3afcbdd6a79faa",
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Function to get all documents from a collection
export const getCollectionData = async (collectionName: string) => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));

    // Convert querySnapshot to array of data
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    console.log("Collection Data:", data);
    return data;
  } catch (error) {
    console.error("Error reading collection:", error);
    throw error;
  }
};

export { db };

// // Usage
// getCollectionData("yourCollectionName").then((data) => {
//   console.log(data); // Array of document data
// });
