import React, { useEffect } from "react";
import { AppProps } from "../interface";

function Products({ lang }: AppProps) {
  useEffect(() => {
    window.addEventListener("message", function (event) {
      if (event.data && event.data.type === "FROM_IFRAME") {
        window.location.href = `${lang}/walletpi`;
      }
    });
    return () => {
      // document.body.removeChild(script);
    };
  }, []);

  return (
    <iframe
      src="/pichamall/index.html"
      width="100%"
      height="100%"
      title="hello"
      // frameBorder="0"
      style={{
        borderWidth: 0,
        width: "100%",
        height: "100%",
        padding: 0,
        margin: 0,
      }}
    />
  );
}

export default Products;
